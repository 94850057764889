.people {
  padding-top: 3rem;
  padding-bottom: 1rem;
  display: grid;
  place-items: center;
  background-color: #35d48a;
  /* background: hsla(152, 65%, 60%, 0.75); */
}

.people .row {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.people .people-title {
  font-size: 3em;
  font-weight: 900;
}
.people .people-title::selection {
  background-color: black;
  color: white;
}
.single-person {
  width: 280px;
  height: 330px;
  border-radius: 1rem;
  border: solid 3px black;
  box-shadow: 14px 14px 0 -3px black, 14px 14px 0 0 black;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 10%); */
  margin-bottom: 30px;
  overflow: hidden;
  padding: 20px;
  background-color: white;
}

.single-person img {
  width: 100px;
  border-radius: 50%;
  border: solid 3px black;
}
.single-person .job {
  /* color: #58dc9e; */
  color: #35d48a;
}
.single-person .job::selection {
  /* color: #58dc9e; */
  color: white;
  background-color: #35d48a;
}
.single-person a {
  /* color: #99b47c;  */
  color: #35d48a;
}
.single-person a:hover {
  color: #181a19;
}

.people .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 1500px) {
  .people .people-title {
    font-size: 2em;
  }
  .people .row {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .people .people-title {
    font-size: 2em;
  }
  .people .row {
    grid-template-columns: 1fr;
  }
}
