img {
  width: 130px;
}
.navbar {
  background-color: white;
  text-decoration: none;
  list-style: none;
  position: absolute;
  top: 0;
  height: 70px;
  /* padding: 1em 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* box-sizing: border-box; */
}

.logo {
  padding-top: 1em;
}

/* .navbar .nav-links ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  margin: 0;
}

.navbar .nav-links ul li {
  margin: 0 25px;
  cursor: pointer;
}

.navbar .nav-links ul li a {
  text-decoration: none;
  color: black;
}

.navbar .nav-links a::after {
  display: block;
  content: "";
  width: 0;
  height: 2px;
  margin: 0 auto;
  background-color: black;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.navbar .nav-links ul li a:hover::after {
  width: 100%;
}

.navbar .menu {
  display: none;
  right: 40px;
  top: 20px;
  height: 30px;
  position: absolute;
} */

@media screen and (max-width: 900px) {
  /* .navbar .menu {
    display: block;
    z-index: 3;
  } */

  .nav-links {
    display: none;
  }
  .navbar {
    display: block;
    text-align: center;
    padding: 20px 0 0 0;
    height: auto;
  }
  .logo {
    margin: 0;
    padding: 0;
  }

  /* .nav-links.expanded {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aquamarine;
    z-index: 2;
  }

  .nav-links.expanded ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .navbar .nav-links.expanded ul li {
    margin: 30px 0;
    font-size: 30px;
    font-weight: 300;
  } */
}
