.screens {
  /* padding-top: 3rem;
  padding-bottom: 1rem; */
  display: grid;
  /* place-items: center; */
  height: 500px;
  overflow-y: hidden;
  place-items: right;
  background-image: url(../assets/images/screens3.png);

  background-size: 220%;
  background-position: 40% 40%;
  background-repeat: repeat;
}

/* .screens img {
  width: 1000px;
  height: auto;
  object-position: 0px -100px;
} */

/* 
@media screen and (max-width: 1156px) {
  .trust .row {
    grid-template-columns: 1fr 1fr;
  }
}
*/
@media screen and (max-width: 768px) {
  .screens {
    height: 300px;
    background-size: 600%;
    background-position: 40% 40%;
  }
}
