.forms {
  padding: 3rem 2rem;
  margin: 0 10%;
  border-radius: 30px;
  /* background: hsla(152, 65%, 60%, 0.75); */
  background-color: #35d48a;
}
.forms .title {
  font-size: 2em;
  font-weight: 500;
}
.inputs {
  display: flex;
  flex-direction: column;
}

input[type="submit"] {
  font-weight: 500;
  border-style: none;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  /* font-size: 1em; */
  padding: 0px;
  text-align: center;
  width: 180px;
  margin-left: 1em;
}
input[type="email"] {
  border-style: none;
  border-radius: 10px;
  background-color: #fff;
  /* cursor: pointer; */
  padding: 0;
  vertical-align: center;
  padding-left: 1em;
}

input[type="submit"]:hover {
  background-color: #181a19;
  color: white;
  font-weight: 500;
}
.each {
  margin: 0.5em;
}
input {
  display: inline-block;
  width: 300px;
  height: 44px;
  margin: 0.2em 0;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(70 90 86);
  font-weight: 300;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde1ed;
  border-radius: 8px;
  /* border-color: #11d99b; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  outline: none;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  input[type="email"] {
    width: 260px;
    height: 2.6em;
  }
  .inputs {
    align-items: center;
  }
  .forms {
    padding: 1em;
    margin: 0 1em;
  }
  input[type="submit"] {
    margin-left: 0;
  }
}
