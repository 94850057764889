.clients {
  /* background: hsla(152, 65%, 60%, 0.75); */
  /* background-color: #35d48a; */

  padding-top: 1em;
  padding-bottom: 1em;
  /* padding-bottom: 1rem; */
  display: grid;
  place-items: center;
}

.clients .title {
  font-size: 2em;
  font-weight: 500;
}
.clients .row {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.clients .column {
  padding-left: 2rem;
  padding-right: 2rem;
}

.single-client {
  /* padding: 1em 2em; */
  width: 450px;
  height: 420px;
  border-radius: 20px;
  border: solid 3px black;
  box-shadow: 14px 14px 0 -3px #35d48a, 14px 14px 0 0 black;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */
  margin-bottom: 30px;
  overflow: hidden;
  /* background-color: #35d48a; */
}

.single-client .tlt {
  height: 30%;
  font-size: 4em;
  font-weight: 900;
  display: flex;
  background-color: #35d48a;
  padding-left: 5%;
  align-items: center;
  border-bottom: solid 3px black;
}

.single-client .tlt::selection {
  background-color: black;
  color: #35d48a;
}
.single-client .desc {
  padding: 2em;
}

.single-client h3 {
  font-size: 4em;
  font-weight: 900;
  margin: 0;
  margin-bottom: 1em;
}
.single-client p {
  text-align: justify;
  font-weight: 300;
  font-size: 1.5em;
}
.single-client p::selection {
  background-color: black;
  color: white;
}

.single-client img {
  height: 150px;
  width: auto;
}

@media screen and (max-width: 1156px) {
  .clients .row {
    grid-template-columns: 1fr;
  }

  .single-client {
    width: 300px;
    border-radius: 1rem;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
    margin-bottom: 30px;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .single-client .tlt {
    height: 30%;
    font-size: 2.5em;
    justify-content: center;
    padding: 0;
    border-bottom: solid 3px black;
  }
  .single-client .desc {
    padding: 1em;
  }
  .clients .row {
    grid-template-columns: 1fr;
    margin: 0;
  }

  .clients .column {
    /* padding-left: 0.1rem;
    padding-right: 0.1rem; */
    margin: 0;
  }
  .single-client p {
    text-align: justify;
    font-weight: 300;
    font-size: 1.3em;
  }

  .single-client {
    width: 280px;
    box-shadow: 14px 14px 0 -3px #35d48a, 14px 14px 0 0 black;
    /* border-radius: 1rem;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
    margin-bottom: 30px;
    overflow: hidden; */
  }
}
