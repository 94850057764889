.quote {
  font-weight: 900;
  font-size: 6em;
  margin-right: 0.5em;
}
.line {
  color: #35d48a;
}

.quote::selection {
  background-color: #181a19;
  color: white;
}

.line::selection {
  background-color: #35d48a;
  color: white;
}
@media screen and (max-width: 768px) {
  .quote {
    font-weight: 900;
    font-size: 2em;
  }
}
