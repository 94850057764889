.steps {
  /* padding-top: 3rem; */
  padding-bottom: 1rem;
  display: grid;
  place-items: center;
}

/* .steps h2 {
  font-size: 2em;
  font-weight: 500;
  margin: 0;
} */
.steps .title {
  font-size: 3em;
  font-weight: 900;
}
.steps .title::selection {
  background-color: black;
  color: white;
}

.steps .descr {
  margin-top: 1em;
}
/* .bg-title {
  font-size: 2.5em;
  font-weight: 500;
  font-style: italic;
  display: inline;
  word-break: break-word;
  background-image: linear-gradient(
    rgba(88, 220, 158, 0.75),
    rgba(88, 220, 158, 0.75)
  );
  background-position: bottom 9px right 0px;
  background-size: 100% 30%;
  background-repeat: no-repeat;
  padding: 10px;
  margin: 10px;
} */
.steps .row {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.steps .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

.single-step {
  /* padding: 2em 1em 1em 1em; */
  color: black;
  width: 320px;
  height: 400px;
  border: 3px solid black;
  border-radius: 1rem;
  /* border-color: black;
  border-width: 1px; */
  background-color: white;
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 5%); */
  margin-bottom: 30px;
  overflow: hidden;
}
.single-step .image {
  height: 57%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-step .image .handshake {
  width: 160px;
  height: auto;
}
.single-step .image .mobile {
  width: 280px;
  height: auto;
}
.single-step .title {
  height: 14%;
  font-size: 1.5em;
  display: flex;
  font-weight: 600;
  padding-left: 5%;
  /* justify-content: center; */
  align-items: center;
  /* line-height: 20%; */
  background-color: #35d48a;
  color: black;
  border-top: solid 2px black;
}
.single-step .title::selection {
  background-color: black;
}

.single-step .desc {
  height: 25%;
  background-color: black;
  color: white;
  padding: 1em 1em 0.5em 1em;
  font-weight: 300;
  font-size: 1.1em;
}

.single-step h3 {
  font-size: 1em;
  /* font-weight: 400; */
  margin: 0em;
  /* line-height: 2em; */
  /* color: white; */
  width: 100%;
}
.single-step p {
  /* text-align: left; */
  text-align: justify;
  padding: 0;
  margin: 0;
}

.single-step p::selection {
  background-color: white;
  color: black;
}
.single-step img {
  height: 100%;
  width: auto;
}

@media screen and (max-width: 1156px) {
  .steps .row {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .steps .title {
    font-size: 2em;
  }
  .single-step {
    width: 340px;
  }
  .single-step .title {
    font-size: 1.5em;
    font-weight: 600;
  }
  .steps .row {
    grid-template-columns: 1fr;
  }
  .bg-title {
    font-size: 1.7em;
  }
}
