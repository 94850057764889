:root {
  --bg: #f0dba5;
  --text: #151515;
  /* --blck: #181818; */
  --yellow: #edb74d;
  --red: #181818;
  --green: #35d48a;
}

body {
  height: 100vh;
  margin: 0;

  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; */
  /* gap: 3em; */

  /* background-color: var(--bg); */
  /* font-family: "Recoleta"; */

  /* margin: 0 10em; */
}

h1 {
  /* font-family: "Anton", sans-serif; */
  font-family: "Bebas Neue", sans-serif;
  /* font-family: "Paytone One", sans-serif; */
  font-weight: 900;
  line-height: 3.4em;
  font-size: 2.5em;
  font-weight: normal;
  padding-top: 3.5em;
}
/* 
h2 {
  font-size: 32px;
}

p {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: inherit;
} */

/* body::before,
body::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;

  opacity: 30%;
} */

body::before {
  /* background: #7fd7a2; */
  background: white;
  filter: url(#noiseFilter);
}

/* Blobs */
.blob-cont {
  right: 2em;
  top: 30%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: flex-end;
  z-index: -2;
  height: 700px;
  width: 700px;
  position: absolute;
  /* right: 5em; */
}

.blob {
  border-radius: 100px;
  /* filter: blur(30px); */
}

.blck {
  background-color: var(--blck);
  position: absolute;
  top: 200px;
  left: 100px;
  height: 200px;
  width: 200px;

  animation: black 8s infinite ease;
}

.green {
  background-color: var(--green);
  position: absolute;
  top: 80px;
  right: -20px;
  height: 200px;
  width: 250px;

  animation: green 8s infinite ease;
}

.red {
  background-color: var(--red);
  position: absolute;
  right: 0;
  top: 300px;
  height: 250px;
  width: 200px;

  animation: red 8s infinite linear;
}

@keyframes blck {
  0% {
    top: 200px;
    left: 100px;
    transform: scale(1);
  }
  30% {
    top: 400px;
    left: 150px;
    transform: scale(1.2);
  }
  60% {
    top: 100px;
    left: 200px;
    transform: scale(1.3);
  }
  100% {
    top: 200px;
    left: 100px;
    transform: scale(1);
  }
}

@keyframes green {
  0% {
    top: 80px;
    right: -20px;
    transform: scale(1.2);
  }
  30% {
    top: 300px;
    right: -20px;
    transform: scale(1);
  }
  60% {
    top: 200px;
    right: 100px;
    transform: scale(1);
  }
  100% {
    top: 80px;
    right: -20px;
    transform: scale(1.2);
  }
}

@keyframes red {
  0% {
    top: 250px;
    right: 0px;
    transform: scale(1);
  }
  30% {
    top: 150px;
    right: 150px;
    transform: scale(1.4);
  }
  60% {
    top: 250px;
    right: 100px;
    transform: scale(1);
  }
  100% {
    top: 250px;
    right: 0px;
    transform: scale(1);
  }
}

.homec {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  width: 100%;
  min-height: 100vh;
  gap: 3em;
  /* background: linear-gradient(to right, #151515 1px, transparent 1px) 0 0,
    linear-gradient(to right, #151515 1px, transparent 1px) 0 100%,
    linear-gradient(to left, #151515 1px, transparent 1px) 100% 0,
    linear-gradient(to left, #151515 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, #151515 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, #151515 1px, transparent 1px) 100% 0,
    linear-gradient(to top, #151515 1px, transparent 1px) 0 100%,
    linear-gradient(to top, #151515 1px, transparent 1px) 100% 100%; */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 5%;
}

.hero-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3em;
  color: #181a19;
}

/* .buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
} */
/* 
.button {
  padding: 1em 3em;
  border: 1px solid var(--text);
  font-size: 18px;
} */
/* 
.primary {
  background-color: var(--text);
  color: #ded3b6;
} */

.bg-word {
  font-style: italic;
  font-weight: 900;
  color: var(--green);
  display: inline;
  word-break: break-word;
  /* background-image: linear-gradient(
    rgba(88, 220, 158, 1),
    rgba(88, 220, 158, 1)
  ); */
  /* background-position: bottom 9px right 0px;
  background-size: 100% 30%;
  background-repeat: no-repeat;
  padding: 10px;
  margin: 10px; */
  /* background-color: #6fb18a; */
  /* line-height: 2em; */
  /* background-position: bottom; */
}
.bg-word::selection {
  background-color: #35d48a;
  color: #181a19;
}

.social-icons-c {
  width: 20%;
  display: flex;
  cursor: pointer;
  /* flex-direction: column;
    justify-content: center; */
}

.iconc {
  margin-top: 2em;
  margin-right: 2em;
  color: black;
  font-size: 30px;
  cursor: pointer;

  /* padding: 0em 2em 0em 0em; */
}
.infoc {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* position: absolute; */
  /* width: 50%; */
}
.titlec {
  /* margin-top: 1em;
  margin-bottom: 0; */
  /* padding-left: 5%; */
}
.titlec p {
  /* color: #7fd7a2; */
  color: #181a19;
  /* color: black; */
  font-size: 3em;
  /* font-weight: 900; */
  z-index: 99;
  text-align: left;
  margin: 0;
  padding: 0;
  width: max-content;
}
.titlec p::selection {
  color: white;
  background-color: #181a19;
}

.subtitlec {
  font-size: 1.7em;
  width: max-content;
  color: #181a19;
  font-weight: 300;
  text-align: left;
  /* text-shadow: #35d48a 2px 5px; */
}
p.subtitlec {
  /* background-color: white; */
}

.subtitlec::selection {
  background-color: #181a19;
  color: white;
}

.linkc {
  text-align: center;
  cursor: pointer;
}

.social-icons-c .iconc:hover {
  color: var(--green);
}

.social-icons-c .iconc {
  cursor: pointer;
}

.city_image {
  position: absolute;
  top: 300px;
  right: 30px;
  width: 600px;
  z-index: -99;
}

.buttonc {
  display: flex;
  justify-content: flex-start;
  /* display: flex; */
  text-align: center;
  margin-top: 1.5em;
  /* padding-left: 5%; */
  font-size: 20px;
  padding: 16px;
  /* width: 205px; */
  cursor: pointer;
  border-style: none;
  border-radius: 10px;
  background-color: #181a19;
  color: white;
  font-weight: 700;
  border: solid 1px #181a19;
}

.buttonc:hover {
  background-color: rgba(24, 26, 25, 0.07);
  color: #181a19;
  font-weight: 700;
  border: solid 1px #181a19;
}

@media screen and (max-width: 768px) {
  .homec {
    /* top: 100px; */
    left: 0px;
    margin-bottom: 2em;
    /* background-color: white; */
  }
  .bottom-line {
    display: flex;
  }
  .city_image {
    position: inherit;
    width: 260px;
  }
  .infoc {
    /* justify-content: baseline; */
    /* display: block; */
  }
  h1 {
    /* font-family: "Anton", sans-serif; */
    /* font-family: "Bebas Neue", sans-serif; */
    /* font-family: "Paytone One", sans-serif; */
    font-weight: 900;
    line-height: 3.4em;
    font-size: 2.5em;
    font-weight: normal;
    padding-top: 1.5em;
  }
  .titlec p {
    font-size: 2em;
    font-weight: 600;
    line-height: 1em;
    width: 80%;
    color: #181a19;
    /* mix-blend-mode: difference; */
  }
  .bg-word {
    margin: 0;
    /* mix-blend-mode: difference; */
  }
  .subtitlec {
    font-size: 1.2em;
    /* padding-top: 2em; */
    width: 80%;
    margin: 0;
    color: #181a19;

    /* background-color: white; */
    /* color: #35d48a; */
    font-weight: 400;
    /* mix-blend-mode: difference; */
    /* background-blend-mode: difference; */
  }
  p.subtitlec {
    margin-right: 0;
    line-height: 1em;
    /* mix-blend-mode: difference; */
  }
  .buttonc {
    margin-top: 5em;
  }
  .social-icons-c {
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    /* width: 100vw; */
    padding-left: 1em;
    /* padding-top: 3em; */
    margin: 0;
    /* mix-blend-mode: difference; */
    /* margin-left: 5%; */
  }
  .social-icons-c .iconc {
    margin-top: 0.5em;
    margin-right: 2em;
    color: black;
    font-size: 30px;
  }
  /* .city_image {
    position: absolute;
    top: 550px;
    right: 30px;
    width: 250px;
    z-index: -99;
  } */
  .red {
    display: none;
  }
  .green {
    display: none;
  }
  /* position: absolute;
    right: 0;
    top: 360px;
    height: 300px;
    width: 200px;
    mix-blend-mode: difference; */
  /* background-color: white; */
  /* background-color: var(--blck); */
  /* position: absolute;
    top: 20px;
    left: 10px;
    height: 20px;
    width: 200px; */
  /* top: 200px;
    left: 100px; */
  /* animation: none; */
  /* } */
  /* @keyframes blck {
    0% {
      top: 200px;
      left: 100px;
      transform: scale(1);
    }
    30% {
      top: 400px;
      left: 150px;
      transform: scale(1.2);
    }
    60% {
      top: 100px;
      left: 200px;
      transform: scale(1.3);
    }
    100% {
      top: 200px;
      left: 100px;
      transform: scale(1);
    }
  } */
}
