.trust {
  padding-top: 3rem;
  padding-bottom: 1rem;
  display: grid;
  place-items: center;
}
.trust .title {
  font-size: 2.5em;
  font-weight: 900;
  margin-bottom: 0.5em;
}
.trust .title::selection {
  background-color: black;
  color: white;
}
.trust .row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
}
.trust .row-2 {
  display: grid;
  /* height: 140px; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}

.single-logo {
  width: 200px;
  /* border-radius: 1rem;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%); */
  margin-bottom: 30px;
  overflow: hidden;
}

.single-logo .uc3m {
  width: 100px;
  /* height: 100px; */
  /* width: auto; */
}

.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  min-height: 160px;
  border-radius: 1rem;
  border: solid 3px black;
  box-shadow: 8px 8px 0 -3px black, 8px 8px 0 0 black;
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 10%); */
  /* margin-bottom: 30px; */
  padding: 10px;
  background-color: white;
}
.awards .emoji {
  font-size: 1.5em;
}
.awards .emoji::selection {
  background-color: black;
}
.awards .name {
  /* font-size: 1.5em; */
}
.awards .company {
  /* background-color: #35d48a; */
  color: #35d48a;
  font-weight: 800;
  /* font-size: 1.5em; */
}
.awards .txt {
  font-weight: 600;
}
.awards .txt::selection {
  background-color: black;
  color: white;
}
.awards .year {
  color: black;
  /* font-weight: 800; */
}
.awards .year::selection {
  /* background-color: #35d48a; */
  /* color: white; */
}
.column {
  padding-left: 1rem;
  padding-right: 1rem;
}
.win {
  color: black;
}
/* rotating text*/
#container {
  height: 100px;
  margin: 0%;
}

#circle {
  position: relative;
  margin-top: -140px;
  /* padding-bottom: 80px; */
  /* width: 100%; */
  /* padding-bottom: 100%; */
  /* overflow: hidden; */
}

#circle text {
  /* font-family: "Helvetica Neue", Arial; */
  font-size: 16px;
  font-weight: 800;
}

#circle svg {
  /* position: absolute; */
  /* left: 0;
  top: 0; */
  width: 100%;
  height: 300px;

  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 8s;
  -moz-animation-duration: 8s;
  -ms-animation-duration: 8s;
  -o-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}
@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}
@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

@media screen and (max-width: 1156px) {
  .trust .row {
    grid-template-columns: 1fr 1fr;
  }
  .trust .row-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .trust .title {
    font-size: 2em;
  }
  .awards {
    margin-bottom: 2em;
  }
  .trust .row {
    grid-template-columns: 1fr;
  }
  .trust .row-2 {
    grid-template-columns: 1fr;
  }
}
