.footer {
  background-color: black;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3em 0 4em;
}
.love {
  color: white;
}
.love::selection {
  background-color: white;
  color: black;
}
.footer .icon {
  color: white;
  font-size: 20px;
  margin-right: 1em;
  /* display: flex; */
  /* flex-direction: column; */
}
.footer .icon:hover {
  color: #35d48a;
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 1em 0 1em 1em;
    flex-direction: column;
    justify-content: space-around;
  }
  .love {
    font-size: 0.9em;
  }
  .footer .icon {
    font-size: 1.5em;
    margin-right: 1em;
  }
}
