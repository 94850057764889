.press {
  padding-top: 3rem;
  padding-bottom: 1rem;
  display: grid;
  place-items: center;
}
.press .title {
  font-size: 2.5em;
  font-weight: 900;
  margin-bottom: 0.5em;
}
.press .title::selection {
  background-color: black;
  color: white;
}
.press .row {
  /* padding-top: 4rem; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}

.single-logo {
  width: 200px;
  /* border-radius: 1rem;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%); */
  margin-bottom: 30px;
  overflow: hidden;
  transition: transform 0.2s;
}
.single-logo img {
  width: 150px;
}
.pr:hover {
  transform: scale(1.1);
}
.single-logo .uc3m {
  width: 100px;
}

.column {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 1156px) {
  .press .title {
    font-size: 2em;
  }
  .press .row {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .press .title {
    font-size: 2em;
  }
  .press .row {
    grid-template-columns: 1fr;
  }
}
